<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  paginatorInfo: {
    type: Object,
    default: () => ({}),
  },
  maxVisibleButtons: {
    type: Number,
    default: 10,
  },
})

const emits = defineEmits(['goToPage'])

const showPagination = computed(() => {
  return props.paginatorInfo.last_page > 1
})

const pages = computed(() => {
  const visiblePages = []
  let startPage
      = props.paginatorInfo.current_page - Math.floor(props.maxVisibleButtons / 2)
  let endPage
      = props.paginatorInfo.current_page + Math.floor(props.maxVisibleButtons / 2)

  if (startPage < 1) {
    endPage += Math.abs(startPage) + 1
    startPage = 1
  }

  if (endPage > props.paginatorInfo.last_page) {
    startPage -= endPage - props.paginatorInfo.last_page
    endPage = props.paginatorInfo.last_page
  }

  for (let page = startPage; page <= endPage; page++) {
    if (page > 0) {
      visiblePages.push({
        name: page,
        isDisabled: page === props.paginatorInfo.current_page,
      })
    }
  }

  if (visiblePages.length > 0)
    return visiblePages

  return 0
})

const pagesIncludesFirst = computed(() => {
  if (pages.value)
    return pages.value.some(page => page.name === 1)

  return false
})

const pagesIncludesLast = computed(() => {
  if (pages.value) {
    return pages.value.some(
      page => page.name === props.paginatorInfo.last_page,
    )
  }
  return false
})

function goToPage(page) {
  emits('goToPage', page)
}
</script>

<template>
  <div v-if="showPagination" class="inline-flex items-center divide-x divide-gray-300 rounded overflow-hidden">
    <button
      v-if="!pagesIncludesFirst"
      class="mr-4 flex items-center justify-center px-3 h-8 font-medium text-xs text-gray-700 cursor-pointer disabled:cursor-not-allowed"
      :class="{
        'bg-gray-200': paginatorInfo.current_page !== 1,
        'voix-admin-bg voix-admin-text-light': paginatorInfo.current_page === 1,
      }"
      @click="goToPage(1)"
    >
      1...
    </button>

    <button
      v-for="page in pages"
      :key="page"
      class="flex items-center justify-center px-3 h-8 font-medium text-xs text-gray-700 cursor-pointer disabled:cursor-not-allowed"
      :class="{
        'bg-gray-200': paginatorInfo.current_page !== page.name,
        'voix-admin-bg voix-admin-text-light':
          paginatorInfo.current_page === page.name,
      }"
      @click="goToPage(page.name)"
    >
      {{ page.name }}
    </button>

    <button
      v-if="!pagesIncludesLast"
      class="ml-4 flex items-center justify-center px-3 h-8 font-medium text-xs text-gray-700 cursor-pointer disabled:cursor-not-allowed"
      :class="{
        'bg-gray-200': paginatorInfo.current_page !== paginatorInfo.last_page,
        'voix-admin-bg voix-admin-text-light':
          paginatorInfo.current_page === paginatorInfo.last_page,
      }"
      @click="goToPage(paginatorInfo.last_page)"
    >
      ...{{ paginatorInfo.last_page }}
    </button>
  </div>
</template>
