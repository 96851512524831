<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import get from 'lodash/get'
import { useDebounceFn } from '@vueuse/core'

import type { ComputedRef, Ref } from 'vue'

import DropdownSelect from '@voix/components/chrome/controls/DropdownSelect.vue'
import type { VoixFilterInterface } from '@voix/types'
import CheckboxInput from '../chrome/controls/CheckboxInput.vue'
import VoixPaginator from './VoixPaginator.vue'
import VoixTableActions from './VoixTableActions.vue'
import { useCookie, useNuxtApp } from '#imports'

export interface Props {
  tableName?: string
  items: Array<{ id: string, localizations: [{ language_code: string }] }>
  languageCode: string
  first: number
  columns: Array<any>
  paginatorInfo: object
  sortColumn: string
  sortDirection: string
  interactionMode: string
  selectedItems?: Array<any>
  canGloballySelectItems?: boolean
  isGloballySelected?: boolean
  localization?: boolean
  actions?: Array<{
    label: string
    action: (item: any) => void
  }>
}

const props = withDefaults(defineProps<Props>(), {
  tableName: '',
  items: () => [],
  first: 0,
  languageCode: 'en',
  columns: () => [],
  paginatorInfo: () => ({}),
  sortColumn: '',
  sortDirection: '',
  interactionMode: 'none',
  selectedItems: () => [],
  canGloballySelectItems: false,
  isGloballySelected: false,
  localization: false,
})

const emit = defineEmits([
  'update:sortColumn',
  'update:sortDirection',
  'update:page',
  'update:first',
  'update:languageCode',
  'update:filters',
  'update:selectedItems',
  'update:isGloballySelected',
  'addSelectedItem',
  'goToPage',
  'select',
])

const { $voix } = useNuxtApp()

const editing = ref('')

const setCookies = computed(() => {
  return props.tableName !== ''
})

const sortColumnCookie = useCookie(`${props.tableName}.sortColumn`)
if (sortColumnCookie.value)
  emit('update:sortColumn', sortColumnCookie.value)

const sortDirectionCookie = useCookie(`${props.tableName}.sortDirection`)
if (sortDirectionCookie.value)
  emit('update:sortDirection', sortDirectionCookie.value)

function setSortCookies(column: string, direction: string) {
  if (!setCookies.value)
    return

  sortColumnCookie.value = column

  sortDirectionCookie.value = direction
}

function setSort(column: string) {
  if (props.sortColumn === column) {
    if (props.sortDirection === 'asc') {
      emit('update:sortDirection', 'desc')
      setSortCookies(column, 'desc')
    }
    else {
      emit('update:sortColumn', '')
      emit('update:sortDirection', '')
      setSortCookies('', '')
    }
  }
  else {
    emit('update:sortColumn', column)
    emit('update:sortDirection', 'asc')
    setSortCookies(column, 'asc')
  }
}

const pageCookie = useCookie(`${props.tableName}.page`)
if (pageCookie.value)
  emit('update:page', Number.parseInt(pageCookie.value, 10))

function setPageCookie(page: string) {
  if (!setCookies.value)
    return

  pageCookie.value = page
}

function setPage(page: number) {
  emit('update:page', page)
  setPageCookie(page.toString())
}

const columnOptions = computed(() =>
  props.columns.map((column: any) => ({
    label: column.label,
    value: column.field,
    key: column.field,
  })),
)

const firstCookie = useCookie(`${props.tableName}.first`)
function setFirstCookie(number: string) {
  if (!setCookies.value)
    return

  firstCookie.value = number
}

function updateFirst(event: any) {
  emit('update:first', Number.parseInt(event.target.value, 10))
  setFirstCookie(event.target.value)
}

function updateLanguageCode(event: any) {
  emit('update:languageCode', event.target.value)
  setFirstCookie(event.target.value)
}

// Functions to render the actual table

function getFieldValue(item: object, field: string, format: (string: any) => string | undefined) {
  if (field === 'language_code') {
    const language = $voix.options.languages.find((language: any) => language.code === get(item, field))
    if (language)
      return language.icon
  }

  if (format)
    return format(get(item, field))
  return get(item, field)
}

function setEditingVisibleColumns(value: string) {
  if (editing.value === value)
    editing.value = ''
  else
    editing.value = value
}

const visibleColumnNames: Ref<Array<any>> = ref([])
props.columns.forEach((column: any) => {
  if (column.visible === true)
    visibleColumnNames.value.push(column.field)
})

const visibleColumns: Ref<Array<any>> = computed(() => {
  return props.columns.filter((column: any) => {
    if (column.field === 'language_code' && (!props.localization || $voix.options.languages.length < 2))
      return false

    return visibleColumnNames.value.includes(column.field)
  })
})

const searchableColumns: ComputedRef<Array<any>> = computed(() => {
  return props.columns.filter((column: any) => column.searchable === true)
})

function isSearchable(field: string) {
  return searchableColumns.value.some(column => column.field === field)
}

const filters: { [key: string]: VoixFilterInterface } = reactive({})

const filtersCookie = useCookie(`${props.tableName}.filters`)
if (filtersCookie.value) {
  Object.assign(filters, filtersCookie.value)
  // Duplicate this sucker
  const finalFilters = JSON.parse(JSON.stringify(filters))
  updateFilters(finalFilters)
}

function setFiltersCookie(filters: string) {
  if (!setCookies.value)
    return

  filtersCookie.value = filters
}

const setFilter = useDebounceFn(() => {
  // Reset the page to 1
  setPage(1)

  // Duplicate this sucker
  const finalFilters = JSON.parse(JSON.stringify(filters))

  // We set the cookie before the augmentation to contains for easier rehydration
  setFiltersCookie(JSON.stringify(finalFilters))
  updateFilters(finalFilters)
}, 1000)

function updateFilters(finalFilters: string) {
  // Change "contains" to a proper "like" filter
  Object.keys(finalFilters).forEach((field: string) => {
    const filter = finalFilters[field]
    if (filter.op === 'contains') {
      filter.op = 'like'
      filter.value = `%${filter.value}%`
    }
  })

  const finalFiltersStr = JSON.stringify(finalFilters)
  emit('update:filters', finalFiltersStr)
}

function clearFilter(field: string) {
  delete filters[field]
  setFilter()
}

function toggleFilter(field: string) {
  if (filters[field]) {
    delete filters[field]
  }
  else {
    filters[field] = {
      field,
      op: 'contains',
      value: ``,
      clause: 'where',
    }
  }
}

const allVisibleSelected = computed(() => {
  return props.selectedItems.length === props.items.length
})

function toggleAll() {
  if (props.canGloballySelectItems && allVisibleSelected.value) {
    // Select every record possible
    emit('update:isGloballySelected', true)
    emit('update:selectedItems', [])
  }
  else if (
    props.selectedItems.length === props.items.length
    || props.isGloballySelected
  ) {
    // Deselect all
    emit('update:isGloballySelected', false)
    emit('update:selectedItems', [])
  }
  else {
    // Select all visible
    emit('update:isGloballySelected', false)
    emit(
      'update:selectedItems',
      props.items.map((item: any) => item.id),
    )
  }
}

function clickItem(item: any) {
  if (props.interactionMode === 'select')
    toggle(item)

  else if (props.interactionMode === 'link')
    emit('select', item)
}

function isSelected(item: any) {
  return props.selectedItems.includes(item.id)
}

function toggle(item: any) {
  if (isSelected(item)) {
    emit(
      'update:selectedItems',
      props.selectedItems.filter(selectedItem => selectedItem !== item.id),
    )
  }
  else {
    emit('addSelectedItem', item.id)
  }
}

const shouldShowLanguageOptions = computed(() => {
  console.log($voix.options.options)
  return props.localization && $voix.options.languages.length > 1
})

const activeLanguageDropdown: Ref<string | null> = ref(null)
function showLanguageDropdown(item: { id: string }) {
  if (activeLanguageDropdown.value === item.id)
    activeLanguageDropdown.value = null
  else
    activeLanguageDropdown.value = item.id
}
</script>

<template>
  <div>
    <div class="flex justify-between items-center">
      <div>
        <VoixPaginator :paginator-info="paginatorInfo" @go-to-page="setPage" />
      </div>
      <div class="text-gray-500 text-sm py-2 flex justify-end space-x-3">
        <div v-if="shouldShowLanguageOptions" class="flex relative text-2xs font-medium">
          <select
            :value="languageCode"
            class="appearance-none border-transparent p-0 pr-6 text-xs bg-gray-200 pl-4 rounded uppercase"
            @change="updateLanguageCode"
          >
            <option
              v-for="language in $voix.options.languages"
              :key="language.code"
              :value="language.code"
            >
              {{ language.icon }}
            </option>
          </select>
        </div>
        <div class="relative text-2xs font-medium">
          <button
            class="flex items-center space-x-1 bg-gray-200 px-4 py-1.5 rounded"
            @click="setEditingVisibleColumns('columns')"
          >
            <span class="uppercase">Visible Columns</span>
            <svg
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
          </button>
          <div class="fixed inset-0" :class="{ 'pointer-events-none': editing === '' }" @click="editing = ''" />
          <div v-show="editing === 'columns'" class="absolute z-20 right-0 top-0 mt-6 bg-white shadow-lg w-64 p-3">
            <DropdownSelect
              id="visible-columns" v-model="visibleColumnNames" :options="columnOptions" :multiple="true"
              :searchable="false" placeholder="Visible Columns"
            />
          </div>
        </div>
        <div v-if="first > 0" class="flex relative text-2xs font-medium">
          <select
            :value="first"
            class="appearance-none border-transparent p-0 pr-6 text-xs bg-gray-200 pl-4 rounded uppercase"
            @change="updateFirst"
          >
            <option :value="10">
              10
            </option>
            <option :value="25">
              25
            </option>
            <option :value="50">
              50
            </option>
            <option :value="100">
              100
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="rounded-lg  bg-gray-100 py-4">
      <table class="w-full text-gray-500">
        <thead>
          <tr>
            <th v-if="interactionMode === 'select'" class="px-4 border-b-2 border-gray-200 w-5">
              <CheckboxInput :selected="allVisibleSelected" :global-selected="isGloballySelected" @click="toggleAll" />
            </th>
            <th
              v-for="column in visibleColumns" :key="column.field"
              class="relative px-3 pb-2 border-b-2 border-gray-200 text-left"
            >
              <div class="flex items-center space-x-2">
                <button v-if="column.sortable" class="text-gray-300" @click="setSort(column.field)">
                  <svg viewBox="7 7 12 12" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                    <path
                      stroke-linecap="round" stroke-linejoin="round" fill="none" :class="{
                        'voix-admin-text':
                          sortColumn === column.field
                          && sortDirection === 'desc',
                      }" d="M 9.685 13.786 L 13.435 17.536 L 17.185 13.786"
                    />
                    <path
                      stroke-linecap="round" stroke-linejoin="round" fill="none" :class="{
                        'voix-admin-text':
                          sortColumn === column.field
                          && sortDirection === 'asc',
                      }" d="M 9.705 11.44 L 13.455 7.69 L 17.205 11.44"
                    />
                  </svg>
                </button>

                <div v-if="filters[column.field]" class="flex space-x-1 items-center">
                  <span class="uppercase text-2xs voix-admin-text font-black">{{
                    column.label
                  }}</span>
                  <select
                    v-model="filters[column.field].op"
                    class="py-2 px-4 pr-8 text-xs rounded  voix-admin-bg-lightest voix-admin-text border border-gray-300 appearance-none"
                    @change="setFilter"
                  >
                    <option value="contains">
                      Has
                    </option>
                    <option value="=">
                      =
                    </option>
                    <option value="<>">
                      ≠
                    </option>
                  </select>
                  <input
                    v-model="filters[column.field].value" type="text"
                    class="py-2 px-4 text-xs rounded  voix-admin-bg-lightest voix-admin-text border border-gray-300 appearance-none w-36"
                    placeholder="Value to Filter By" @input="setFilter"
                  >
                  <button @click="clearFilter(column.field)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-5 h-5"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div v-else class="flex items-center">
                  <button
                    v-if="isSearchable(column.field)" class="uppercase text-2xs text-gray-400 font-black"
                    @click="toggleFilter(column.field)"
                  >
                    {{
                      column.label
                    }}
                  </button>
                  <span v-else class="uppercase text-2xs text-gray-400 font-black">{{
                    column.label
                  }}</span>
                </div>
              </div>
            </th>
            <th
              v-if="actions"
              class="relative px-3 pb-2 border-b-2 border-gray-200 text-left"
            >
              &nbsp;
            </th>
            <th
              v-if="interactionMode === 'link'"
              class="relative px-3 pb-2 border-b-2 border-gray-200 text-left"
            >
              &nbsp;
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in items" :key="item.id"
            class="group cursor-pointer hover:voix-admin-bg-light duration-200 border-b border-gray-200"
          >
            <td v-if="interactionMode === 'select'" class="px-4">
              <CheckboxInput :selected="isSelected(item)" :global-selected="isGloballySelected" @click="toggle(item)" />
            </td>
            <td
              v-for="column in visibleColumns" :key="column.field"
              class="py-3 px-4 text-xs text-gray-600 group-hover:text-gray-900 font-medium"
              @click="clickItem(item)"
            >
              <div v-if="column.field === 'language_code'">
                <div v-if="activeLanguageDropdown === item.id" class="fixed inset-0 z-20" @click.prevent.stop="activeLanguageDropdown = null" />
                <div class="relative">
                  <button class="py-0.5 px-1 bg-white border-gray-300 border rounded-sm flex items-center space-x-0.5" @click.prevent.stop="showLanguageDropdown(item)">
                    <span>{{ getFieldValue(item, column.field, column.format) }}</span>
                    <Icon name="heroicons:chevron-down-20-solid" class="w-4 h-4" />
                  </button>
                  <ul v-if="activeLanguageDropdown === item.id" class="absolute top-0 left-0 z-20 min-w-[200px] mt-6 bg-white divide-y divide-y-gray-300 shadow-sm border border-gray-100 *:px-3 *:py-2">
                    <li v-if="item.localizations.length < 1" class="italic">
                      No translations of this page
                    </li>
                    <li v-for="language in item.localizations" :key="language.code">
                      <a
                        :href="`/voix/studio?editing=${language.path}`"
                        class="block py-0.5 px-1 hover:bg-gray-100"
                      >
                        <span>{{ $voix.options.languages.find((l) => l.code === language.language_code).icon }}</span>
                        {{ $voix.options.languages.find((l) => l.code === language.language_code).name }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <template v-else>
                {{ getFieldValue(item, column.field, column.format) }}
              </template>
            </td>

            <td v-if="actions">
              <VoixTableActions :actions="actions" :item="item" class="relative" />
            </td>

            <td v-if="interactionMode === 'link'" @click="clickItem(item)">
              <Icon name="heroicons:chevron-right-20-solid" class="w-5 h-5 voix-admin-text-dark transform duration-200 group-hover:translate-x-1" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="items.length === 0" class="flex justify-center mt-12">
      <div class="text-gray-400 bg-gray-100 p-8 rounded-lg w-full md:w-1/2 text-center font-medium">
        No records found
      </div>
    </div>

    <VoixPaginator class="mt-2" :paginator-info="paginatorInfo" @go-to-page="setPage" />
  </div>
</template>
